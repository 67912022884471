<template>
  <div class="editable-input-precarite">
    <div class="edit-view" v-if="editing">
      <v-text-field
        class="mt-2 height-input"
        :type="type"
        v-model="newValue"
        @keyup.enter="update"
        @keyup.esc="editing = false"
        dense
        clearable
        outlined
        color="#5C2DD3"
        item-color="#5C2DD3"
      >
      </v-text-field>
      <div class="edit-action flex justify-content-end mt-1 mb-2">
        <v-spacer></v-spacer>
        <v-btn color="#5C2DD3" dark @click="update" small class="mr-2">
          {{ $t('btnValider') }}
        </v-btn>
        <v-btn text @click="resetInput" outlined small>
          {{ $t('btnCancel') }}
        </v-btn>
      </div>
    </div>
    <div class="show-view" v-else @click="editing = true">
      <div class="value font-sz-11 font-text">
        {{ value | format }} €
        <!-- <font-awesome-icon class="show-view-pencil ml-2" icon="pencil-alt" /> -->
        <v-icon class="color-crm-bold font-sz-20 show-view-pencil">
          mdi-square-edit-outline</v-icon
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EditableInputPrecarite',
  props: {
    field: { required: true },
    entity: { required: true },
    value: { required: true },
    type: { default: 'number' },
    updateFunction: { required: true }
  },
  data() {
    return {
      editing: false,
      newValue: this.value
    }
  },
  methods: {
    resetInput() {
      this.newValue = this.value
      this.editing = false
    },
    async update() {
      if (this.value != this.newValue) {
        const key = this.field == 'IDF' ? 'revenus_idf' : 'revenus_province'
        await this.updateFunction({
          entity: this.entity,
          data: {
            [key]:
              Math.round((parseFloat(this.newValue) + Number.EPSILON) * 100) /
              100
          }
        })
        this.newValue = this.value
      }
      this.editing = false
    }
  },
  watch: {
    editing(value) {
      if (value) {
        this.$nextTick(() => {
          if (this.$refs.autofocus) {
            this.$refs.autofocus.focus()
          }
        })
      }
    }
  },
  filters: {
    format: function(value) {
      if (!value) {
        return 0
      }
      return value
        .toFixed(2)
        .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
        .replace(/\./g, ',')
    }
  }
}
</script>

<style lang="scss" scoped>
.editable-input-precarite {
  .edit-view {
    .v-text-field.v-text-field--enclosed .v-text-field__details {
      display: none;
    }
    display: inline-block;
    .input-style {
      text-align: center;
    }
    // .edit-action-input {
    //   display: flex;

    //   div {
    //     cursor: pointer;
    //     color: #5e5e5e;
    //     border: 1px solid #a9adb1;
    //     user-select: none;
    //     margin: 0;
    //     padding: 0px 4px;
    //     border-radius: 2px;
    //     font-size: 11px;
    //     font-weight: 600;
    //     &.green {
    //       background: #08a742;
    //       color: #fff;
    //     }
    //   }
    // }
  }
  .show-view {
    display: flex;
    justify-content: space-between;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    .show-view-pencil {
      color: #9e9e9e;
      opacity: 0;
      display: none;
      transition: opacity 0.2s ease;
    }
    &:hover {
      .show-view-pencil {
        display: inline-block;
        opacity: 1;
      }
    }
  }
  .edit-action {
    .v-btn.v-size--small {
      font-size: 10px;
    }
  }
}
</style>
<style lang="scss">
.editable-input-precarite {
  .edit-view {
    .v-text-field.v-text-field--enclosed .v-text-field__details {
      display: none;
    }
    .v-input__slot {
      min-height: 30px !important;
    }
    .v-input__append-inner {
      margin-top: 4px !important;
    }
  }
}
</style>

<template>
  <div class="page-content-precarite">
    <div class="header">
      <div class="style-title-setting-global">
        {{
          $i18n.locale == 'fr'
            ? 'Gestion des Précarités'
            : 'Precarity Management'
        }}
      </div>
      <div
        v-if="getPrecariteLoading"
        class="color-crm font-sz-12 font-text mr-3"
      >
        {{ $t('loading') }}
      </div>
    </div>
    <div v-if="getPrecariteError" class="error-msg mr-3">
      <ul v-if="Array.isArray(getPrecariteError)">
        <li v-for="(e, index) in getPrecariteError" :key="index">
          {{ e }}
        </li>
      </ul>
      <span v-else>{{ getPrecariteError }}</span>
    </div>
    <div class="loader-content" v-if="initLoading">
      <v-progress-circular
        indeterminate
        class="ml-3"
        color="#5C2DD3"
      ></v-progress-circular>
    </div>
    <div class="precarite mt-2" v-else>
      <v-simple-table class="precarite-table" v-if="precarite" fixed-header>
        <!-- table -->
        <thead>
          <tr class="header-tr">
            <th>
              {{
                $i18n.locale == 'fr'
                  ? 'Nombre de personne'
                  : 'Number of persons'
              }}
            </th>
            <th>
              {{
                $i18n.locale == 'fr'
                  ? 'Plafonds de revenus IDF'
                  : 'Income limits IDF'
              }}
            </th>
            <th>
              {{
                $i18n.locale == 'fr'
                  ? 'Plafonds de revenus Province'
                  : 'Plafonds de revenus Province'
              }}
            </th>
          </tr>
        </thead>
        <template v-for="table in precarite">
          <tbody :key="'pb-' + table.title">
            <tr class="sub-header-tr">
              <th></th>
              <th>
                {{ $options.filters.translateName(table.title, $i18n.locale) }}
              </th>
              <th></th>
            </tr>
            <tr v-for="item in table.data" :key="'pv-' + item.id">
              <th>
                <div>{{ item.nbr_personne }}</div>
              </th>
              <td>
                <EditableInputPrecarite
                  :entity="item"
                  type="number"
                  :value="item.revenus_idf"
                  :updateFunction="updatePrecarite"
                  :field="'IDF'"
                />
              </td>
              <td>
                <EditableInputPrecarite
                  :entity="item"
                  type="number"
                  :value="item.revenus_province"
                  :updateFunction="updatePrecarite"
                  :field="'Province'"
                />
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import EditableInputPrecarite from '@/components/CrmIla26Component/EditableInputPrecarite'

export default {
  name: 'Precarite',
  components: {
    EditableInputPrecarite
  },
  data() {
    return {
      initLoading: true
    }
  },
  methods: {
    ...mapActions(['updatePrecarite', 'fetchPrecarite'])
  },
  computed: {
    ...mapGetters(['getPrecarite', 'getPrecariteLoading', 'getPrecariteError']),
    precarite: function() {
      return [
        {
          title: 'Précarité',
          data: this.getPrecarite.filter(p => p.type == 'Precarite')
        },
        {
          title: 'Grande Précarité',
          data: this.getPrecarite.filter(p => p.type == 'Grand Precarite')
        }
      ]
    }
  },
  filters: {
    translateName: function(name, locale) {
      switch (name) {
        case 'Précarité':
          return locale === 'fr' ? 'Précarité' : 'Precarity'

        case 'Grande Précarité':
          return locale === 'fr' ? 'Grande Précarité' : 'Large Precarity'
      }
      return ''
    }
  },
  async mounted() {
    this.initLoading = true
    await this.fetchPrecarite()
    this.initLoading = false
  }
}
</script>
<style lang="scss" scoped>
.page-content-precarite {
  .header {
    display: flex;
    align-items: center;
  }
  .precarite {
    max-height: calc(100vh - 210px) !important;
    height: calc(100vh - 210px) !important;
    margin-bottom: 0;
    overflow-y: auto;
    overflow-x: auto;
    padding: 0px 0px;
    cursor: pointer;
    .precarite-table {
      text-align: center;
      font-size: 12px;
      tbody {
        th {
          font-size: 11px;
          text-align: center;
        }
        td {
          height: 35px;
        }
        tr {
          th {
            height: 38px !important;
          }
        }
      }
      thead {
        th {
          font-size: 11px;
          color: black;
          vertical-align: middle;
          text-align: center;
        }
      }
      .header-tr {
        th {
          height: 35px !important;
        }
      }
      .sub-header-tr {
        th {
          text-align: center;
          background: #5c2dd3;
          color: #fff;
          font-size: 12px;
          text-transform: uppercase;
          height: 28px !important;
        }
      }
    }
  }
  .precarite::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }
  .precarite::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #f0eef8;
    border-radius: 4px;
    background-color: rgba(238, 238, 238, 1) !important;
  }
  .precarite::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(189, 189, 189, 1);
  }
}
</style>
